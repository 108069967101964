<template>
    <el-drawer title="安装详情" :visible.sync="visible" direction="rtl" :before-close="visibleClose" :modal="false" size="50%">
        <div class="drawer-container">
            <div class="infoBox">
                <div class="item-title">客户信息</div>
                <div class="myLine">
                    <div class="column">
                        <span>姓名:&nbsp;&nbsp;{{ infoItem.userName }}</span>
                        <span>详细地址:&nbsp;&nbsp;{{ infoItem.detaAddress }}</span>
                        <!--            <span>紧急联系方式:&nbsp;&nbsp;{{ infoItem.urgentPhone }}</span>-->
                    </div>
                    <div class="column">
                        <span>手机号:&nbsp;&nbsp;{{ infoItem.userPhone }}</span>
                        <!--            <span>紧急联系人:&nbsp;&nbsp;{{ infoItem.urgentPeople }}</span>-->
                    </div>
                </div>
            </div>
            <div class="infoBox" style="padding-right: 100px">
                <div class="item-title">紧急联系人</div>
                <div class="table">
                    <el-table
                        :data="infoItem.urgentConcatInfos"
                        style="width: 100%"
                        border
                        :header-cell-style="{ textAlign: 'center', ...headerCellStyle }"
                        :cell-style="{ textAlign: 'center' }"
                    >
                        <el-table-column prop="urgentPeople" label="姓名" />
                        <el-table-column prop="urgentPhone" label="联系电话" />
                    </el-table>
                </div>
            </div>
            <div class="infoBox" style="padding-right: 100px">
                <div class="item-title">安装信息</div>
                <div class="table">
                    <el-table :data="tableData" style="width: 100%" border :header-cell-style="{ textAlign: 'center', ...headerCellStyle }" :cell-style="{ textAlign: 'center' }">
                        <el-table-column prop="equipmentName" label="设备类别" />
                        <el-table-column prop="equipmentId" label="设备ID" />
                        <el-table-column prop="installLocation" label="安装位置" />
                    </el-table>
                </div>
                <template v-if="tableDataBefore.length > 0">
                    <div class="item-title">原计划安装设备</div>
                    <div class="table">
                        <el-table :data="tableDataBefore" border :header-cell-style="{ textAlign: 'center' }" :cell-style="{ textAlign: 'center' }">
                            <el-table-column prop="name" label="设备类别" />
                            <el-table-column prop="number" label="设备数量" />
                        </el-table>
                    </div>
                </template>
                <p style="font-size: 14px">安装截止日期：{{ infoItem.endDate }}</p>
            </div>
            <div class="infoBox">
                <div class="item-title">安装人员信息</div>
                <div class="myLine">
                    <div class="column">
                        <span>姓名:&nbsp;&nbsp;{{ infoItem.installWorker }}</span>
                    </div>
                    <div class="column">
                        <span>手机号:&nbsp;&nbsp;{{ infoItem.installerPhone }}</span>
                    </div>
                </div>
            </div>
            <div class="infoBox">
                <div class="item-title">安装进度</div>
                <div class="time-line">
                    <div v-for="(el, index) in timeLine" :key="index" class="template-line">
                        <div class="template-left">
                            <div class="time">{{ el.operateTime }}</div>
                            <div v-if="el.installer" class="person">安装人员: {{ el.installer }}</div>
                        </div>
                        <div class="template-circle" :class="{ pending: el.orderStatus != '已完成', completed: el.orderStatus === '已完成' || '已回访' }" />
                        <div class="template-right" :style="{ borderLeft: index === timeLine.length - 1 ? 'none' : '1px solid #909399' }">
                            <div class="status">{{ el.orderStatus }}</div>
                            <div v-if="el.cancelReason" class="person">{{ el.cancelReason }}</div>
                            <!--<div v-if="el.change && el.change.length > 0" class="person">
                                <span v-for="(it, i) in el.change" :key="i">{{ it }}</span>
                            </div>-->
                            <div v-if="el.describe" class="detail">{{ el.describe }}</div>
                            <div v-if="el.images && el.images.length > 0" class="imgs">
                                <div v-for="(el, index) in el.images" :key="index">
                                    <el-image v-if="el" :src="el" :preview-src-list="[el]" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </el-drawer>
</template>

<script>
import { getDetail } from '@/api/homeSave'
export default {
    props: {
        visible: Boolean,
        orderId: String,
        infoItem: {
            type: Object,
            default: () => {
                return {
                    userName: 'ttttt',
                    detaAddress: 'sdsdfsdf',
                    urgentPhone: 186346436346,
                    userPhone: 1346346346,
                    urgentPeople: 'wetewgwerwet',
                    date: '2022-05-10 16:30:07',
                    installWorker: 'test',
                    installerPhone: 12430698490869
                }
            }
        }
    },
    data() {
        return {
            tableData: [],
            tableDataBefore: [],
            timeLine: []
        }
    },
    mounted() {
        this.getInfo()
    },
    methods: {
        visibleClose() {
            this.$emit('closeDialog', 'true', true)
        },
        emitRefresh() {
            this.$emit('updatePage', 'true', true)
        },
        async getInfo() {
            const res = await getDetail({
                orderId: this.orderId
            })
            this.timeLine = res.data.orderTimestampVos
            this.tableData = res.data.current
            this.tableDataBefore = res.data.before
        }
    }
}
</script>

<style lang="scss" scoped>
/deep/ .el-drawer__header {
    padding: 20px 0 0 0;
    margin-bottom: 0;
    font-size: 25px;
    &::before {
        content: '';
        width: 9px;
        height: 30px;
        background: #3fcf89;
        float: left;
        margin-right: 20px;
    }
}
.drawer-container {
    width: 100%;
    color: #333333;
    .infoBox {
        border-top: 1px solid #cccccc;
        padding-top: 24px;
        padding-bottom: 47px;
        padding-left: 29px;
        .item-title {
            font-size: 16px;
            margin-bottom: 4%;
            font-weight: bold;
        }
        .myLine {
            display: flex;
            .column {
                display: flex;
                flex-direction: column;
            }
            span {
                display: inline-block;
                font-size: 14px;
                margin-right: 165px;
                margin-bottom: 20px;
                &:last-child {
                    margin-bottom: 0px;
                }
            }
        }
        &:first-child {
            border-top: none;
        }
    }
    .table {
        margin-bottom: 15px;
    }
    .time-line {
        margin-top: 25px;
        .title {
            font-size: 16px;
            margin-bottom: 28px;
        }
        .template-line {
            display: flex;
            font-size: 16px;
            padding-left: 30px;
            margin-left: 50px;
            margin-bottom: 0px;
            font-size: 14px;
            .template-left {
                font-size: 14px;
                padding: 0 0 20px 0;
                width: 150px;
                .person {
                    font-size: 12px;
                    margin-top: 10px;
                    margin-left: 15px;
                }
            }
            .template-circle {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                position: relative;
                z-index: 10;
            }
            .completed {
                background-image: url('../../assets/imgs/completed.png');
            }
            .pending {
                background-image: url('../../assets/imgs/pending.png');
            }
            .template-right {
                border-left: 1px solid #909399;
                padding: 0 0 20px 30px;
                margin-left: -10px;
                position: relative;
                z-index: 1;
                width: 350px;
                div {
                    &.person,
                    &.detail {
                        color: #909399;
                        font-size: 14px;
                        margin-top: 10px;
                        span {
                            display: block;
                        }
                    }
                }
                .imgs {
                    display: flex;
                    flex-wrap: wrap;
                    margin-top: 10px;
                    .el-image {
                        object-fit: cover;
                        width: 120px;
                        height: 80px;
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}
</style>
